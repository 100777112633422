/* eslint-disable class-methods-use-this */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import Button from '@audacious/components/components/Button';
import {
    PageContainer,
    PageContainerGroup,
} from '@audacious/components/components/Page';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import { Paragraph, Text } from '@audacious/components/components/Typography';
import { Row, Column } from '@audacious/components/components/Grid';
import Data, {
    DataDateProperty,
    DataSelectProperty,
    DataTextProperty,
    DataMaskProperty,
    DataAccess,
} from '@audacious/components/components/Data';
import { faClockRotateLeft } from '@audacious/icons/regular/faClockRotateLeft';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import { withRouter } from 'react-router-dom';
import { UsaStates } from 'usa-states';
import { v4 as uuid4 } from 'uuid';
import formatDate from '../../../../../common/util/format-date';
import QueryType from '../../../../../common/query/query-type';
import {
    queryPatients,
    clearQueries,
} from '../../../../../actions/patient-discovery-actions';
import { queryMedications } from '../../../../../actions/medications-actions';
import { setPatientFormFields } from '../../../../../actions/patient-form-actions';
import { PatientFieldsPropType } from '../../../../prop-types/patient-fields';
import { SEARCH, SEARCH_HISTORY } from '../../../routes';
import { localizationShape } from '../../../../prop-types/localization';
import getMedEntitlement from '../../../../../common/util/get-med-entitlement';
import './patient-demo-form.scss';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

const { states } = new UsaStates({ includeTerritories: true });

const stateOptions = states.map(state => ({
    text: `${state.name} - ${state.abbreviation}`,
    key: state.abbreviation,
}));

const genderOptions = [
    {
        text: 'Male',
        key: 'M',
    },
    {
        text: 'Female',
        key: 'F',
    },
    {
        text: 'Unknown',
        key: 'U',
    },
];

const INVALID_VALUE_MSG = 'Enter a valid value';

function zipCodeMask(value) {
    if (isNil(value) || value.length <= 5) {
        return [/\d/, /\d/, /\d/, /\d/, /\d/];
    }

    return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
}

class PatientDemoForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dirty: false,
        };

        this.preventDirty = false;
        this.isClearing = false;

        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleExecuteStart = this.handleExecuteStart.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleDemographicsSubmit = this.handleDemographicsSubmit.bind(
            this,
        );
        this.handleSearchClick = this.handleSearchClick.bind(this);

        this.dataRef = createRef();
        this.firstFieldRef = createRef();
    }

    componentDidUpdate(prevProps) {
        const { searchId: prevSearchId } = prevProps;
        const { searchId } = this.props;

        if (!searchId && prevSearchId) {
            this.handleClear();
        }

        if (this.isClearing && this.firstFieldRef.current) {
            this.firstFieldRef.current.focus();
            this.isClearing = false;
        }
    }

    handleDemographicsSubmit(submittedFields) {
        const { dirty } = this.state;
        const {
            searchHasBegun,
            searchId,
            hasMedEntitlement,
            fluxibleContext: { executeAction },
        } = this.props;

        const requestId = dirty ? '' : searchId;

        const fieldsCopy = cloneDeep(submittedFields);

        // modify field formats for submission
        const dateString = formatDate(fieldsCopy.dob, 'YYYYMMDD');

        // Include pulseAggregationId so that medications and patient discovery can be linked in audit
        set(fieldsCopy, 'pulseAggregationId', uuid4());

        fieldsCopy.dob = dateString;

        if (searchHasBegun || dirty) {
            executeAction(clearQueries);
        }

        executeAction(setPatientFormFields, submittedFields);
        executeAction(queryPatients, {
            fields: fieldsCopy,
            requestId,
        });

        if (hasMedEntitlement) {
            executeAction(queryMedications, {
                fields: fieldsCopy,
            });
        }

        this.setState({
            dirty: false,
        });
    }

    handleClear() {
        const {
            blankFields,
            history,

            fluxibleContext: { executeAction },
        } = this.props;

        this.preventDirty = true;

        executeAction(setPatientFormFields, blankFields);

        this.isClearing = true;
        this.setState({
            dirty: false,
        });

        if (this.dataRef && this.dataRef.current) {
            this.dataRef.current.set(blankFields);
        }

        executeAction(clearQueries);
        history.push(SEARCH);
    }

    handleFormChange() {
        const { dirty } = this.state;

        if (this.preventDirty) {
            this.preventDirty = false;
        } else if (!dirty) {
            this.setState({
                dirty: true,
            });
        }
    }

    handleExecuteStart(_, errors) {
        return isNil(errors);
    }

    handleSearchClick() {
        this.dataRef.current.execute();
    }

    render() {
        const {
            localization,
            searchHasBegun,
            fields,
            history,
            searchHistoryEnabled,
        } = this.props;
        const { dirty } = this.state;

        const firstNameLabel = `${get(localization, 'firstNameFieldLabel')} *`;
        const lastNameLabel = `${get(localization, 'lastNameFieldLabel')} *`;
        const dateOfBirthLabel = `${get(
            localization,
            'dateOfBirthFieldLabel',
        )} *`;
        const genderLabel = `${get(localization, 'genderFieldLabel')} *`;

        const addressLabel = `${get(localization, 'address1FieldLabel')} **`;
        const zipCodeLabel = `${get(localization, 'zipCodeFieldLabel')} **`;
        const phoneLabel = `${get(localization, 'phoneFieldLabel')} **`;

        const newSearchButton = searchHasBegun ? (
            <Button
                id="new-search-button"
                color="primary"
                variant="opaque"
                leftIcon={faMagnifyingGlass}
                onClick={this.handleClear}
            >
                New Search
            </Button>
        ) : null;

        const searchHistoryButton = searchHistoryEnabled ? (
            <Button
                id="search-history-btn"
                color="primary"
                variant="opaque"
                leftIcon={faClockRotateLeft}
                onClick={() => history.push(SEARCH_HISTORY)}
            >
                Search History
            </Button>
        ) : null;

        return (
            <PageContainer asCard>
                <PageContainerGroup>
                    <Data
                        id="PatientSearchForm"
                        aria-label="Patient Search"
                        baseValue={fields}
                        onChange={this.handleFormChange}
                        ref={this.dataRef}
                        validateOnExecute
                        validateOnBlur
                        showResultsOnTouch
                        showResultsOnExecute
                        onExecuteStart={this.handleExecuteStart}
                        onExecute={this.handleDemographicsSubmit}
                    >
                        <DataAccess
                            attributes={{
                                additionalOne: {
                                    enabled: true,
                                    onValidate: (value) => {
                                        if (isEmpty(value.address1)
                                            && isEmpty(value.zip)
                                            && isEmpty(value.homePhone)) {
                                                return 'additional one required';
                                            }
                                        
                                            return null;
                                    },
                                }
                            }}
                        >
                            {(context) => {
                                let additionalOneInvalid = false;

                                if (
                                    context.form.executed
                                    && !isEmpty(context.property.results?.$)
                                ) {
                                    additionalOneInvalid = true;
                                }

                                return (
                                    <>
                                        <Row gutter="24" enableAfter>
                                            <Column width="fill">
                                                <Paragraph>
                                                    <Text weight="bold">
                                                        For best search results, complete as many fields as possible.
                                                    </Text>
                                                    <Text>{' *fields are required.'}</Text>
                                                </Paragraph>
                                                <Paragraph>
                                                    <Text color={additionalOneInvalid ? 'danger' : null}>
                                                        In addition to the required fields ( * ), you must enter at least one of the following fields prior to searching for patient: Address, Zip Code, or Phone. ** 
                                                    </Text>
                                                </Paragraph>
                                                </Column>
                                                <Column width="content">
                                                    {newSearchButton}
                                                    {searchHistoryButton}
                                                </Column>
                                        </Row>
                                        <Row gutter="16">
                                            <Column width={[null, null, null, '3']}>
                                                <DataTextProperty
                                                    id="patientDemoLastName"
                                                    path="lastName"
                                                    label={lastNameLabel}
                                                    // ref={this.firstFieldRef}
                                                    required={[true]}
                                                    minLength={[1, 'Required']}
                                                />
                                            </Column>
                                            <Column width={[null, null, null, '3']}>
                                                <DataTextProperty
                                                    id="patientDemoFirstName"
                                                    path="firstName"
                                                    label={firstNameLabel}
                                                    required={[true]}
                                                    minLength={[1, 'Required']}
                                                />
                                            </Column>
                                            <Column width={[null, '4', null, '2']}>
                                                <DataTextProperty
                                                    id="patientDemoMiddleName"
                                                    path="middleName"
                                                    label={get(
                                                        localization,
                                                        'middleNameFieldLabel',
                                                    )}
                                                />
                                            </Column>
                                            <Column width={[null, '4', null, '2']}>
                                                <DataDateProperty
                                                    id="patientDemoDob"
                                                    path="dob"
                                                    label={dateOfBirthLabel}
                                                    required={[true]}
                                                    validDate={[true, 'Date is invalid']}
                                                    maxDate={[
                                                        new Date(),
                                                        'Future dates are invalid',
                                                    ]}
                                                    toDate={new Date(currentYear, currentMonth)}
                                                />
                                            </Column>
                                            <Column width={[null, '4', null, '2']}>
                                                <DataSelectProperty
                                                    id="patientSearchGender"
                                                    path="gender"
                                                    label={genderLabel}
                                                    required={[true]}
                                                    options={genderOptions}
                                                    aria-label="Gender"
                                                />
                                            </Column>
                                        </Row>
                                        <Row gutter="16">
                                            <Column width={[null, null, null, '4']}>
                                                <DataTextProperty
                                                    id="patientDemoAddress1"
                                                    path="address1"
                                                    label={addressLabel}
                                                    invalid={additionalOneInvalid}
                                                />
                                            </Column>
                                            <Column width={[null, '6', null, '2']}>
                                                <DataTextProperty
                                                    id="patientDemoCity"
                                                    path="city"
                                                    label={get(localization, 'cityFieldLabel')}
                                                />
                                            </Column>
                                            <Column width={[null, '6', null, '2']}>
                                                <DataSelectProperty
                                                    id="patientSearchState"
                                                    path="state"
                                                    label={get(localization, 'stateFieldLabel')}
                                                    options={stateOptions}
                                                    aria-label="State"
                                                />
                                            </Column>
                                            <Column width={[null, '6', null, '2']}>
                                                <DataMaskProperty
                                                    id="patientDemoZip"
                                                    path="zip"
                                                    label={zipCodeLabel}
                                                    mask={zipCodeMask}
                                                    pattern={[
                                                        /(^$)|(^([0-9]{5})(-[0-9]{4})?$)/,
                                                        INVALID_VALUE_MSG,
                                                    ]}
                                                    invalid={additionalOneInvalid}
                                                />
                                            </Column>
                                            <Column width={[null, '6', null, '2']}>
                                                <DataMaskProperty
                                                    id="patientDemoHomePhone"
                                                    path="homePhone"
                                                    label={phoneLabel}
                                                    mask={[
                                                        '(',
                                                        /[1-9]/,
                                                        /\d/,
                                                        /\d/,
                                                        ')',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    pattern={[
                                                        /(^$)|(^\(\d{3}\)\d{3}-\d{4}$)/,
                                                        INVALID_VALUE_MSG,
                                                    ]}
                                                    invalid={additionalOneInvalid}
                                                />
                                            </Column>
                                        </Row>
                                        <Row gutter="16">
                                            <Column>
                                                <ButtonGroup>
                                                    <Button
                                                        id="patientSearch"
                                                        // type="submit"
                                                        onClick={this.handleSearchClick}
                                                        disabled={searchHasBegun && !dirty}
                                                        color="primary"
                                                        variant="fill"
                                                    >
                                                        Search Patient
                                                    </Button>
                                                    {dirty ? (
                                                        <Button
                                                            id="patientSearchClear"
                                                            className="clear-button"
                                                            onClick={this.handleClear}
                                                            color="primary"
                                                            variant="opaque"
                                                        >
                                                            Clear
                                                        </Button>
                                                    ) : null}
                                                </ButtonGroup>
                                            </Column>
                                        </Row>
                                    </>
                                );
                            }}
                        </DataAccess>
                    </Data>
                </PageContainerGroup>
            </PageContainer>
        );
    }
}

PatientDemoForm.propTypes = {
    localization: PropTypes.shape(localizationShape.patientDemoForm).isRequired,
    searchHasBegun: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    fields: PatientFieldsPropType.isRequired,
    blankFields: PatientFieldsPropType.isRequired,
    searchId: PropTypes.string.isRequired,
    hasMedEntitlement: PropTypes.bool.isRequired,
    searchHistoryEnabled: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func.isRequired,
    }).isRequired,
};

PatientDemoForm.defaultProps = {
    history: null,
};

// eslint-disable-next-line no-class-assign
PatientDemoForm = withRouter(PatientDemoForm);

export default connectToStores(
    applyFluxibleContext(PatientDemoForm),
    [
        'LocalizationStore',
        'QueryStore',
        'PatientFormStore',
        'ApplicationContext',
        'Session',
    ],
    context => {
        const localizationStore = context.getStore('LocalizationStore');
        const queryStore = context.getStore('QueryStore');

        const patientFormStore = context.getStore('PatientFormStore');
        const fields = patientFormStore.getFields();
        const blankFields = patientFormStore.getBlankFields();

        return {
            searchHasBegun: queryStore.doesQueryOfTypeExist(
                QueryType.PATIENT_DISCOVERY,
            ),
            localization: localizationStore.getState().patientDemoForm,
            fields,
            blankFields,
            hasMedEntitlement: getMedEntitlement(context),
        };
    },
);
